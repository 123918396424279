<template>
  <section class="conOfAnnualMeeting h-100 mb-5">
    <v-container>
      <v-row class="mt-3">
        <v-col cols="12">
          <Breadcrumb :BreadcrumbList="BreadcrumbList" />
          <HeaderPage :headerPage="{ part1: 'Annual', part2: 'Meetings' }" />
        </v-col>
      </v-row>
      <form class="conFormSubmit">
        <v-row>
          <v-col md="3" sm="6" cols="12">
            <div class="conDefaultInput">
              <v-text-field
                solo
                v-model="search.terms"
                label="Title Or Description"
                hide-details="auto"
              ></v-text-field>
            </div>
          </v-col>
          <v-col md="3" sm="6" cols="12">
            <div class="conSelectOption">
              <v-select
                outlined
                v-model="search.orderBy"
                :items="items"
                item-text="name"
                item-value="value"
                label="Order By"
                hide-details="auto"
                :menu-props="{ bottom: true, offsetY: true }"
                attach
              ></v-select>
            </div>
          </v-col>

          <v-col md="3" sm="6" cols="12">
            <div class="conDatePicker">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    solo
                    v-model="search.date"
                    label="Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="search.date"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </v-col>
          <v-col md="3" sm="6" cols="12">
            <div class="confBtns">
              <v-btn
                class="srearchBnt primaryBtn mr-4"
                @click="onSearch"
                :disabled="isLoadingSearch"
                :loading="isLoadingSearch"
              >
                Search
              </v-btn>
              <v-btn class="clearBtn secondary-outline-btn" @click="onClear">
                clear
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </form>
    </v-container>

    <section class="mt-3" v-if="AnnualMeetings && AnnualMeetings.length > 0">
      <v-container>
        <v-row>
          <v-col
            md="4"
            sm="6"
            cols="12"
            v-for="(annualMeeting, index) in AnnualMeetings"
            :key="index"
          >
            <div
              class="conofcard"
              @click="
                $router.push({
                  name: 'annualMeetingsItems',
                  params: { id: annualMeeting.id },
                })
              "
            >
              <v-card class="mx-auto" max-width="370" tile elevation="0">
                <!-- :src="'https://img.youtube.com/vi/' + media.url + '/0.jpg'" -->
                <div class="conOfCardImg">
                  <v-img
                    height="250"
                    :src="annualMeeting.image"
                    alt="item of the Image"
                  ></v-img>
                </div>
                <v-card-title class="cardTitle">
                  <div class="conOfDate">{{ annualMeeting.date }}</div>
                </v-card-title>
                <v-card-title class="cardTitle">
                  <div class="titleSec">
                    {{ annualMeeting.title }}
                  </div>
                </v-card-title>
                <v-card-text>
                  <div class="cardDesc">
                    {{ annualMeeting.description }}
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="pagination.total > pagination.per_page">
          <v-col cols="12">
            <div class="text-center">
              <v-pagination
                v-model="pagination.current_page"
                :length="pagination.last_page"
                @input="goToPage(pagination.current_page)"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <EmptyState v-else></EmptyState>
  </section>
</template>

<script>
import EmptyState from "@/modules/shared/components/emptystate";
import HeaderPage from "@/modules/shared/components/header-pages";
import Breadcrumb from "@/modules/shared/components/breadcrumbs-pages";
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  data: () => ({
    dialog: false,
    name: "",
    menu: false,
    items: [
      {
        name: "newest",
        value: 1,
      },
      {
        name: "oldest",
        value: 2,
      },
    ],
    search: {
      terms: "",
      date: "",
      orderBy: null,
    },
    updatedFilter: null,
    pagination: {
      current_page: 1,
    },
    BreadcrumbList: [
      {
        text: "Research",
        disabled: true,
      },
      {
        text: "Annual Meetings",
        disabled: false,
      },
    ],
    AnnualMeetings: null,
    isLoading: false,
    isLoadingSearch: false,
    updatedCurrent_page: null,
  }),
  components: {
    EmptyState,
    HeaderPage,
    Breadcrumb,
  },
  methods: {
    onClear() {
      // this.pagination.current_page = 1;
      this.search.terms = "";
      this.search.date = "";
      this.search.orderBy = null;
      this.pathByOaramSearch();
    },
    inzializeForm() {
      this.pagination.current_page = this.$route.query.current_page || 1;
      this.search.terms = this.$route.query.terms || "";
      this.search.date = this.$route.query.date || "";
      this.search.orderBy = parseInt(this.$route.query.orderBy) || null;
    },

    pathByOaramSearch() {
      const query = Object.entries(this.search).reduce((acc, [key, val]) => {
        if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      if (
        JSON.stringify(this.updatedFilter) != JSON.stringify(query) ||
        this.updatedCurrent_page != this.pagination.current_page
      ) {
        this.$router.replace({
          name: "annualMeetings",
          query: { current_page: this.pagination.current_page, ...query },
        });
        this.updatedFilter = query;
        this.updatedCurrent_page = this.pagination.current_page;
      }
    },
    onSearch() {
      this.pagination.current_page = 1;
      this.pathByOaramSearch();
    },
    goToPage() {
      this.pathByOaramSearch();
      this.getListData();
      window.scrollTo(0, 0);
    },
    getListData() {
      this.AnnualMeetings = [];
      this.isLoading = true;
      this.isLoadingSearch = true;

      apiServices
        .post(
          "annual-meetings?page=" + this.pagination.current_page,
          this.search
        )
        .then((res) => {
          if (res) {
            this.AnnualMeetings = res.data;
            this.pagination = res.meta;
            this.isLoading = false;
            this.isLoadingSearch = false;
          } else {
            this.isLoading = false;
            this.isLoadingSearch = false;
          }
        });
    },
  },
  watch: {
    "$route.params.query": {
      handler: function () {
        this.inzializeForm();
        this.getListData();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_annual-meetings.scss";
</style>
